// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const server = 'https://admin-v2.kenza.re/rest/public';
const site = 'https://kenza.re';

export const environment = {
  production: false,
  server_url : `${server}/api/`,
  image_url : `${server}/api/get/media?path=`, 
  site_url : `${site}/`,
  onesignal: {
    app_id: '7f02b9d0-c675-4cfe-ba4b-7868a0a398ed',
    project_id: '290045567642'
  },
  color : '#222222',
  map: {
    zoom: 9,
    lat : -21.1307379,
    lng: 55.5364801,
  },
  path: {
    cache: '_IMG_CACHE'
  },
  basket: false,
  cardRattach: true,
  tva: 8.5,
};
///com.apps.kenza.KenzaNotificationService

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
